<template>
  <div>

  </div>
</template>

<script>
export default {
    created() {

    },
    methods: {
        getView() {
            this.$http.get('/ViewAccess/crmusage')
            .then(() => {
            })
            .catch(() => {
            })
        },
    }
}
</script>

<style>

</style>